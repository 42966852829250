import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useSearchParamsState, useSearchParamsStateFlag } from "../../utils/useSearchParamsState";
import { alpha, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Stack, Tab, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { CoreTag, useCoreTag } from "../Api/ReactQuery";
import userHasPermission from "../../utils/userHasPermission";
import { useStore } from "zustand";
import { appGlobalStore } from "../../AppGlobalStore";
import { permissions } from "../../utils/dciConstants";
import { AddBox, Delete, Edit, IndeterminateCheckBox, Save } from "@mui/icons-material";
import { ControlButton } from "../DciControls";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { postDciApiNoThrow } from "../../utils/callDciApi";
import { useSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import { PARAMETER_TYPE, serializeString } from "../../GraphQLShared";
import dciPaths from "../../utils/dciPaths";
import { useNavigate } from "react-router-dom";
import OrgContainer from "../Organisation/OrgContainer";
import { createTableStore, TableStoreContext, useTableState } from "../Table/Stores/TableStore";
import { TableContainer } from "../Table/TableContainer";
import { Table } from "../Table/Table";
import { TableColumn } from "../Filtering/types";

interface TagViewProps {
    id: number
}

const CoreTagView = ({ id }: TagViewProps) => {
    const [ tabIndex, setTabIndex ] = useSearchParamsState('tab', 'tag');

    if (['tag', 'rules'].indexOf(tabIndex) === -1) {
        setTabIndex('rule');
    }

    return (
        <OrgContainer>
            <TabContext value={tabIndex}>
                <Stack component={Paper} sx={{ height:'100%' }}>
                    <Box>
                        <TabList onChange={(_, v) => setTabIndex(v)}>
                            <Tab label='Core Tag' value='tag' />
                            <Tab label='Rules' value='rules' />
                        </TabList>
                    </Box>
                    <TabPanel sx={{ flexGrow:1, overflow:'hidden' }} value='tag'>
                        <TagTab id={id} />
                    </TabPanel>
                    <TabPanel sx={{ padding:'5px 0px 0px', flexGrow:1, overflow:'hidden' }} value='rules'>
                        <RulesTab id={id} />
                    </TabPanel>
                </Stack>
            </TabContext>
        </OrgContainer>
    )
}

const TagTab = ({ id }: TagViewProps) => {
    const queryClient = useQueryClient();
    const navigate =  useNavigate();
    const { getAccessTokenSilently } = useAuth0();
    const { enqueueSnackbar } = useSnackbar();
    const { isFetching, data } = useCoreTag(id);
    const [ editMode, setEditMode ] = useSearchParamsStateFlag('editMode', false);
    const [ editObject, setEditObject ] = useState<CoreTag | null>(null);

    const currentUser = useStore(appGlobalStore, s => s.currentUser);
    const hasEditPermission = userHasPermission(currentUser, permissions.EDIT_CORE_TAGS);
    const hasDeletePermission = userHasPermission(currentUser, permissions.EDIT_CORE_TAGS);
 
    if (isFetching) {
        return <Typography>Loading...</Typography>
    }

    if (data === undefined || data === null) {
        return <Typography>Core Tag not found.</Typography>
    }

    const beginEdit = () => {
        setEditObject({ ...data });
        setEditMode(true);
    }

    const changesMade = () => {
        if (!editObject) {
            return false;
        }
    
        return editObject.colour?.toLowerCase() !== data.colour?.toLowerCase()
        || editObject.description !== data.description
        || editObject.name !== data.name;
    };

    const saveChanges = async () => {
        if (editObject === null) {
            return;
        }

        const token = await getAccessTokenSilently();
        postDciApiNoThrow(`mutation{editCoreTag(coreTagId:${editObject.coreTagId},name:"${editObject.name.trim()}",description:${serializeString(editObject.description)}"){tagId}}`, token)
        .then(body => {
            if (body.errors) {
                enqueueSnackbar(body.errors[0].message, { variant:'error' });
            } else {
                setEditMode(false);
                queryClient.invalidateQueries({ queryKey: ['core-tag', data.coreTagId] })
                enqueueSnackbar('Saved', { variant:'success' });
            }
        })
        .catch(error => {
            console.error(`[CoreTagView] editTag: ${error}`);
        });
    }

    const deleteTag = async () => {
        if (window.confirm(`Delete Core Tag named '${data.name}'?`) === true) {
            const token = await getAccessTokenSilently();
            postDciApiNoThrow(`mutation{deleteTag(tagId:${id})}`, token)
            .then(body => {
                if (!body.errors) {
                    navigate(dciPaths.tags.buildLink());
                    enqueueSnackbar(`Deleted tag "${data.name}"`, { variant:'success' });
                } else {
                    enqueueSnackbar(body.errors[0].message, { variant:'error' });
                }
            })
            .catch(error => {
                console.error(`[TagView] editTag: ${error}`);
            });
        }
    }

    return <>
        <Stack style={{ height:'100%', width:'100%' }}>
            <Box style={{ flexGrow:1, overflowY:'auto' }}>
                <Box style={{ float:'right' }}>
                    { hasDeletePermission && !editMode && <Button onClick={deleteTag} startIcon={<Delete />}>Delete</Button> }
                    { hasEditPermission && !editMode && <Button startIcon={<Edit />} onClick={beginEdit}>Edit</Button> }
                </Box>
                { editMode
                    ? <TagEdit tag={editObject!} setTag={factory => setEditObject(existing => factory(existing!))} />
                    : <TagReadOnlyView tag={data} />
                }
            </Box>
            { editMode &&
            <Box>
                <ControlButton style={{ marginBottom:'0px' }} onClick={saveChanges} disabled={!changesMade()} startIcon={<Save />} variant='contained'>Save</ControlButton>
                <ControlButton style={{ marginBottom:'0px' }} onClick={() => setEditMode(false)} variant='contained'>Cancel</ControlButton>
            </Box> }
        </Stack>
    </>
}

interface TagEditProps {
    tag: CoreTag
    setTag: (factory: (existing: CoreTag) => CoreTag) => void
}

const TagEdit = ({ tag, setTag }: TagEditProps) => {
    return <Stack gap={2} sx={{ maxWidth:'400px' }}>
        <TextField
            variant='standard'
            label='Name'
            error={tag.name === ''}
            value={tag.name}
            onChange={e => setTag(existing => ({ ...existing, name: e.target.value }))}
        />
        <TextField
            fullWidth
            variant='standard'
            label='Description'
            value={tag.description ?? ''}
            onChange={e => setTag(existing => ({ ...existing, description: e.target.value === '' ? null : e.target.value }))}
        />
    </Stack>
}

const TagReadOnlyView = ({ tag }: { tag: CoreTag }) => {
    return <Stack direction='row' alignItems='center' gap={2}>
        <Stack>
            <Typography variant='h3'>{tag.name}</Typography>
            <Typography>{tag.description}</Typography>
        </Stack>
    </Stack>
}

const createDialogStore = (coreTagId: number) => createTableStore({
    graphQLQueryName: 'allReportTests',
    graphQLQueryColumns: '{reportTestId,ruleDescription}',
    idFromRow: row => row.reportTestId,
    uniqueSortColumn: 'reportTestId'
},
{
    paged: true,
    sortOrder: [{ column:'reportTestId' }],
    //fixedParameters: [{ name:'where.ruleGroupId.neq', type:PARAMETER_TYPE.RAW_NUMBER, value: ruleGroupId }]
}, 'addRules');

type RuleSummary = {
    reportTestId: number,
    ruleDescription: string
}

const dialogColumns: TableColumn[] = [
    { displayName:'Rule', selector:'reportTestId' },
    { displayName:'Description', selector:'ruleDescription' }
];

type AddRuleDialogProps = {
    isOpen: boolean,
    close: (refresh: boolean) => void,
    coreTagId: number
}

const AddRuleDialog = ({ isOpen, close, coreTagId }: AddRuleDialogProps) => {
    const [ store ] = useState(() => createDialogStore(coreTagId))

    return <TableStoreContext.Provider value={store}>
        <DialogInner isOpen={isOpen} close={close} coreTagId={coreTagId} />
    </TableStoreContext.Provider>
}

const DialogInner = ({ isOpen, close, coreTagId }: AddRuleDialogProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const [ refreshRulesTableOnClose, setRefreshRulesTableOnClose ] = useState(false);

    const updateData = useTableState(s => s.updateRowData);
    const selectedData = useTableState(s => s.selectedData);
    const setPage = useTableState(s => s.setCurrentPage);
    const toggleSortColumn = useTableState(s => s.toggleSortColumn);
    
    useEffect(() => {
        if (isOpen) {
            setRefreshRulesTableOnClose(false);
            setPage(0);
            toggleSortColumn('reportTestId', 'reportTestId');
            updateData(false);
        }
    }, [ isOpen ])

    const addRules = async () => {
        const token = await getAccessTokenSilently();
        postDciApiNoThrow(`mutation{addRuleCoreTags(coreTagId:${coreTagId},reportTestIds:[${selectedData.map((r: RuleSummary) => r.reportTestId).join()}])}`, token)
        .then(body => {
            if (body.errors) {
                enqueueSnackbar(body.errors[0].message, { variant:'error' });
            } else {
                setRefreshRulesTableOnClose(true);
                enqueueSnackbar('Successfully added rule(s)', { variant:'success' });
            }
        });
    };

    return (
        <Dialog
            open={isOpen}
            fullScreen={fullScreen}
            maxWidth={'md'}
            fullWidth
            aria-labelledby="form-dialog-title"
        >
            <TableContainer
                title='AddRules'
                paged
                style={{ height:'100%', maxHeight:'600px', minHeight:'400px', width:'100%' }}
                enableExport
            >
                <Table
                    uniqueSortColumn='reportTestId'
                    columns={dialogColumns}
                    idFromValue={row => row.reportTestId}
                    selectable
                />
            </TableContainer>
            
            <DialogActions>
                <Button disabled={selectedData.length === 0} onClick={addRules}>Add</Button>
                <Button onClick={() => close(refreshRulesTableOnClose)}>Done</Button>
            </DialogActions>
        </Dialog>
    );
}

const RulesControls = ({ id }: TagViewProps) => {
    const [ addRuleDialogIsOpen, setAddRuleDialogIsOpen ] = useState(false);
    const { getAccessTokenSilently } = useAuth0();
    const { enqueueSnackbar } = useSnackbar();
    const selectedData = useTableState(s => s.selectedData);
    const updateRowData = useTableState(s => s.updateRowData);

    const closeDialog = (refresh: boolean) => {
        setAddRuleDialogIsOpen(false);
        if (refresh) {
            updateRowData(false);
        }
    }

    const untag = async () => {
        const token = await getAccessTokenSilently();
        postDciApiNoThrow(`mutation{removeRuleCoreTags(coreTagId:${id},reportTestIds:[${selectedData.map(r => r.reportTestId)}])}`, token)
        .then(body => {
            if (body.errors) {
                enqueueSnackbar(body.errors[0].message, { variant:'error' });
            } else {
                updateRowData(false);
                enqueueSnackbar('Saved', { variant:'success' });
            }
        })
        .catch(error => {
            console.error(`[CoreTagView] removeRuleCoreTags: ${error}`);
        });
    }

    return <>
        <Button
            sx={theme => ({ 
                color:theme.palette.text.primary,
                borderColor:theme.palette.divider,
                '&:hover': {
                    borderColor: theme.palette.action.disabledBackground,
                    backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity)
                }
            })}
            style={{ margin:'0px 4px' }}
            endIcon={<AddBox />}
            variant='outlined'
            onClick={() => setAddRuleDialogIsOpen(true)}
            aria-label='add rule'>
                Add
        </Button>
        <Button
            sx={theme => ({ 
                color:theme.palette.text.primary,
                borderColor:theme.palette.divider,
                '&:hover': {
                    borderColor: theme.palette.action.disabledBackground,
                    backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity)
                }
            })}
            style={{ margin:'0px 4px' }}
            endIcon={<IndeterminateCheckBox />}
            variant='outlined'
            onClick={untag}
            disabled={selectedData.length === 0}
            aria-label='remove rule'>
                Remove
        </Button>
        <AddRuleDialog isOpen={addRuleDialogIsOpen} close={closeDialog} coreTagId={id} />
    </>
}

const RulesTab = ({ id }: TagViewProps) => {
    const [ store ] = useState(() => createStore(id))

    return (
        <TableStoreContext.Provider value={store}>
            <TableContainer
                title='Rules'
                paged
                style={{ height:'100%', minHeight:'400px', width:'100%' }}
                enableExport
                toolbarButtons={<RulesControls id={id} />}
            >
                <Table
                    uniqueSortColumn='reportTestId'
                    columns={columns}
                    idFromValue={row => row.reportTestId}
                    selectable
                />
            </TableContainer>
        </TableStoreContext.Provider>
    )
}

const createStore = (id: number) => createTableStore({
    graphQLQueryName: 'reportTestsByTagId',
    graphQLQueryColumns: '{reportTestId,ruleDescription}',
    idFromRow: row => row.reportTestId,
    uniqueSortColumn: 'reportTestId'
},
{
    paged: true,
    sortOrder: [{ column:'reportTestId' }],
    fixedParameters: [{ name: 'coreTagId', type: PARAMETER_TYPE.RAW_NUMBER, value: id }]
});

const columns: TableColumn[] = [
    { displayName: 'Rule ID', selector:'reportTestId' },
    { displayName: 'Description', selector:'ruleDescription' }
];

export { CoreTagView }