import { Autocomplete, Checkbox, InputBase, Typography} from "@mui/material";
import { FilterControlProps } from "./types";
import { AutocompletePopper, FilterAutocomplete } from "./FilterAutocomplete";
import authClient from "../Auth/auth-client";
import { FILTER_TYPE, FilterToParameterConverter, FilterUrlSerializer, registerFilterUrlSerializer, registerParameterConverter } from "./FilterRepository";
import { PARAMETER_TYPE } from "../../GraphQLShared";
import { fetchTags, Tag, useTags } from "../Api/ReactQuery";
import { TagChip } from "../TagPickerMenu";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { FilterTypography } from "../Table/Shared";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const TagFilter = ({ editMode, value, setValue }: FilterControlProps) => {
    const { data, isFetching } = useTags();

    const selectedTags = value as Tag[];

    const getTextValue = (value: Tag[]) => value. length === 1 
        ? selectedTags[0].name
        : `${selectedTags.length} selected`

    const setAutoCompleteValue = (newValue: Tag[]) => {
        setValue(newValue, newValue.length > 0, false);
    }

    if (editMode) {
        return <Autocomplete
            PopperComponent={AutocompletePopper}
            style={{ 
                minWidth:'200px',
                marginRight:'5px',
                height:'26px'
            }}
            options={data ?? []}
            value={selectedTags}
            onChange={(_event, value, _reason) => setAutoCompleteValue(value)}
            getOptionLabel={t => t.name}
            isOptionEqualToValue={(o, v) => o.tagId === v.tagId}
            multiple
            disableCloseOnSelect
            loading={isFetching}
            renderInput={({ InputProps, InputLabelProps, ...rest }) => 
                <InputBase
                    { ...InputProps }
                    {...rest}
                    sx={theme => ({ 
                        border:`1px solid ${theme.palette.text.primary}`,
                        marginRight:'5px',
                        height:'26px',
                        '& > input': {
                            padding:'2px 6px',
                            fontSize:'14px'
                        }
                    })}
                />}
            renderOption={(props, option, state) =>
                <li style={{ padding:'0px 6px', overflow:'hidden' }} {...props}>
                    <Checkbox
                        size='small'
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={state.selected}
                        color='secondary'
                    />
                    <TagChip {...option} />
                </li>
            }
            renderTags={value => <FilterTypography>{getTextValue(value)}</FilterTypography>}
        />
    }

    return (
        <Typography style={{
            padding:'0px 5px',
            fontSize:'14px',
            maxWidth:'150px',
            overflow:'hidden',
            textOverflow:'ellipsis',
            whiteSpace:'nowrap',
            fontStyle:!value || value === '' ? 'italic' : 'inherit'
        }}>
            {selectedTags.length === 1 
                ? ['=', selectedTags[0].name].join(' ')
                : `= ${selectedTags.length} selected`}
        </Typography>
    )
}

const TagFilterDefaultValue = [] as Tag[];

const converter: FilterToParameterConverter = (name: string, value: Tag[]) => ({
    name: name,
    type: PARAMETER_TYPE.TAG,
    value: value
});

registerParameterConverter(FILTER_TYPE.TAG, converter);

const serializer: FilterUrlSerializer = {
    serialize: (f: Tag[]) => { console.log('ser'); return f.map(t => `${t.isCoreTag ? 's' : 'u'}-${t.tagId}`).join(); },
    deserialize: async s => {
        const token = await authClient.getTokenSilently();
        const tags = await fetchTags(token, true);

        return s
            .split(',')
            .filter(v => /[us]-[0-9]+/i.test(v))
            .map(v => {
                const tagId = parseInt(v.substring(2));
                const isCoreTag = v[0].toLowerCase() === 's';

                return tags.find(t => t.isCoreTag === isCoreTag && t.tagId === tagId);
            })
            .filter(t => typeof t !== 'undefined');
    }
}

registerFilterUrlSerializer(FILTER_TYPE.TAG, serializer);

export { TagFilter, TagFilterDefaultValue }